<template>
  <Modal v-model="open" title="文章爬取" footer-hide>
    <Form ref="formDynamic" :model="formDynamic" :label-width="80">
      <!-- <FormItem label="封面图片" prop="img" :rules="{required: true, message: '封面图片必填', trigger: 'blur'}">
        <Upload ref="addUpload" :before-upload="handleUpload" action>
          <Button v-show="!imgUrl" icon="md-cloud-upload">上传封面图片</Button>
          <img v-show="imgUrl" :src="imgUrl" class="uploadImgUrl" />
        </Upload>
      </FormItem> -->
      <FormItem v-for="(item, index) in formDynamic.items" v-if="item.status" :key="index" :label="'地址 ' + item.index" :prop="'items.' + index + '.value'"
        :rules="{required: true, message: '地址 ' + item.index +' 还没填写', trigger: 'blur'}">
        <Row>
          <Col span="14">
          <Input type="text" v-model="item.value" placeholder="公众号文章Url"></Input>
          </Col>
          <Col span="4">
          <i-switch v-model="item.getImg" size="large">
            <span slot="open">同步MP</span>
            <span slot="close">Off</span>
          </i-switch>
          </Col>
          <Col span="4" offset="1">
          <Button @click="handleRemove(index)">删除</Button>
          </Col>
        </Row>
      </FormItem>
      <FormItem v-if="showAddBtn">
        <Row>
          <Col span="12">
          <Button type="dashed" long @click="handleAdd" icon="md-add">加一条</Button>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <Button :loading="loading" type="primary" @click="handleSubmit('formDynamic')">确定</Button>
        <Button @click="handleReset('formDynamic')" style="margin-left: 8px">重置</Button>
      </FormItem>
    </Form>
  </Modal>

</template>
<script>
import { wechatDraftAdd } from '@/api/wechat'
import { wxUpload } from '@/api/upload'
export default {
  computed: {
    showAddBtn () {
      return this.formDynamic.items.filter(v => v.status === 1).length < 8
    }
  },
  data () {
    return {
      index: 1,
      formDynamic: {
        _id: '',
        items: [
          {
            value: '',
            index: 1,
            status: 1,
            getImg: false
          }
        ]
      },
      open: false,
      title: '文章爬取',
      loading: false,
      imgUrl: '',
    }
  },
  methods: {
    init ({ _id }) {
      this.formDynamic._id = _id
      this.open = true
    },
    handleUpload (file) {
      if (file) {
        if (file.size > 1024 * 1024 * 10) {
          this.$Message.error('图片尺寸太大')
          this.$refs['addUpload'].clearFiles()
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            const _base64 = reader.result
            this.imgUrl = _base64 //将_base64赋值给图片的src，实现图片预览
          }
          let formData = new FormData()
          formData.append('wx', file)
          wxUpload(formData)
            .then((res) => {
              if (res.data.code === 200) {
                this.formDynamic.img = res.data.imgUrl
              } else {
                this.$Message.error(err)
              }
            })
            .catch((err) => {
              this.$Message.error(err)
            })
          return false //阻止图片继续上传，使得form表单提交时统一上传
        }
      }
      return false
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.loading = true
          const { _id, items } = this.formDynamic
          const params = {
            _id, urls: items.filter(v => v.status === 1)
          }
          wechatDraftAdd(params).then(res => {
            this.loading = false
            this.open = false
            this.$Message.success('Success!');
          }).catch(e => {
            this.loading = false
            this.$Message.error('Fail!');
          })
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    handleAdd () {
      this.index++;
      this.formDynamic.items.push({
        value: '',
        index: this.index,
        status: 1
      });
    },
    handleRemove (index) {
      this.formDynamic.items[index].status = 0;
    }
  }
}
</script>

<style lang="less" scoped>
.uploadImgUrl {
  width: 50px;
  height: 50px;
}
</style>