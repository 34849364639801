import axios from '@/libs/api.request'

// 爬文章
export const wechatDraftAdd = (info) => {
  return axios.request({
    url: 'wechat/draftAdd',
    data: info,
    method: 'post',
  })
}

// 列表
export const wechatPage = (info) => {
  return axios.request({
    url: 'wechat/page',
    data: info,
    method: 'post',
  })
}
// 删除
export const wechatDel = (info) => {
  return axios.request({
    url: 'wechat/del',
    data: info,
    method: 'post',
  })
}
// 添加
export const wechatAdd = (info) => {
  return axios.request({
    url: 'wechat/add',
    data: info,
    method: 'post',
  })
}
// 修改
export const wechatUpdate = (info) => {
  return axios.request({
    url: 'wechat/update',
    data: info,
    method: 'post',
  })
}
// 查
export const wechatGet = (info) => {
  return axios.request({
    url: 'wechat/get',
    data: info,
    method: 'post',
  })
}
