<template>
  <Modal v-model="open" :title="title">
    <Form ref="formItem" :model="formItem" :label-width="80" :rules="ruleValidate">
      <FormItem label="名称" prop="name">
        <Input v-model="formItem.name" placeholder="请输入公众号名称" clearable />
      </FormItem>
      <FormItem label="原始ID" prop="gzhid">
        <Input v-model="formItem.gzhid" placeholder="请输入公众号原始ID" clearable />
      </FormItem>
      <FormItem label="小程序appid" prop="mpid">
        <Input v-model="formItem.mpid" placeholder="请输入小程序appid" clearable />
      </FormItem>
      <FormItem label="appid" prop="gzhappid">
        <Input v-model="formItem.gzhappid" placeholder="请输入公众号appid" clearable />
      </FormItem>
      <FormItem label="AppSecret" prop="gzhsecret">
        <Input v-model="formItem.gzhsecret" placeholder="请输入公众号AppSecret" clearable />
      </FormItem>
      <FormItem label="cookie" prop="cookie">
        <Input v-model="formItem.cookie" type="textarea" placeholder="请输入公众号cookie" clearable />
      </FormItem>
      <FormItem label="token" prop="token">
        <Input v-model="formItem.token" placeholder="请输入公众号token" clearable />
      </FormItem>
    </Form>
    <div slot="footer" style="text-align: center">
      <Button @click="cancel('formItem')">关闭</Button>
      <Button type="primary" :loading="loading" @click="ok('formItem')">
        提交
      </Button>
    </div>
  </Modal>
</template>
<script>
import { wechatAdd, wechatGet, wechatUpdate } from '@/api/wechat'
export default {
  name: 'WechatAddUpdate',
  data () {
    return {
      formItem: {
        name: '',
        gzhid: '',
        mpid: '',
        gzhappid: '',
        gzhsecret: '',
        cookie: '',
        token: '',
      },
      ruleValidate: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        gzhid: [{ required: true, message: '请输入', trigger: 'blur' }],
        mpid: [{ required: true, message: '请输入', trigger: 'blur' }],
        gzhappid: [{ required: true, message: '请输入', trigger: 'blur' }],
        gzhsecret: [{ required: true, message: '请输入', trigger: 'blur' }],
        cookie: [{ required: true, message: '请输入', trigger: 'blur' }],
        token: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      open: false,
      title: '公众号添加',
      loading: false,
    }
  },
  methods: {
    getWechat (_id) {
      wechatGet({ _id }).then(res => {
        this.formItem = { ...res.data.data }
      })
    },
    init (params) {
      if (params) {
        this.title = '公众号编辑'
        this.getWechat(params._id)
      } else {
        this.title = '公众号添加'
      }
      this.open = true
    },
    ok (name) {
      this.$refs[name].validate((valid) => {
        if (!valid) return
        this.loading = true
        // const postData = JSON.parse(JSON.stringify(this.formItem))
        if (this.formItem._id) {
          wechatUpdate(this.formItem)
            .then((res) => {
              this.loading = false
              if (res.data.code === 200) {
                this.$Message.success('公众号修改成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.loading = false
              this.$Message.error(err)
            })
        } else {
          wechatAdd(this.formItem)
            .then((res) => {
              this.loading = false
              if (res.data.code === 200) {
                this.$Message.success('公众号添加成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.loading = false
              this.$Message.error(err)
            })
        }
      })
    },
    cancel (name) {
      this.formItem = {
        name: '',
        gzhid: '',
        mpid: '',
        gzhappid: '',
        gzhsecret: '',
        cookie: '',
        token: '',
      }
      this.$refs[name].resetFields()
      this.open = false
    },
  },
  mounted () { },
}
</script>
